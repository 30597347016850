import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { translations } from "../../base.js";
import { getTimeAgo } from "../../utils/dates.js";
import { AuthContext } from "../../context/authContext.js";
import RoomEditForm from "./RoomEditForm.jsx";
import EditIcon from "../../images/edit.svg";
import DeleteIcon from "../../images/delete.svg";

const Room = ({ room, deleteRoom }) => {
    const { currentUser } = useContext(AuthContext);
    const [isFormVisible, setIsFormVisible] = useState(false);

    const handleShowForm = () => {
        setIsFormVisible((prev) => !prev);
    };

    const handleEditFormSubmitted = (
        success,
        name,
        description,
        isPublic,
        users
    ) => {
        if (success) {
            setIsFormVisible((prev) => !prev);

            room.name = name;
            room.description = description;
            room.isPublic = isPublic;
            room.users = users;
        }
    };

    const handleEditFormClosed = () => {
        setIsFormVisible(false);
    };

    const handleDelete = async (e) => {
        deleteRoom(room.id);
    };

    // Show buttons if:
    // - User is super admin
    // - User is the room's owner
    const showActionButtons = () => {
        return (
            (room && currentUser && currentUser.id == room.ownerId) ||
            currentUser?.isSuperAdmin
        );
    };

    return (
        <div className="card no-padding" key={room.id}>
            <div className="flex-container">
                <div className="card-content">
                    {!isFormVisible && (
                        <h2>
                            <Link to={`/roomp/${room.id}`}>{room.name}</Link>
                        </h2>
                    )}
                    {!isFormVisible && (
                        <div className="content">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: room.description,
                                }}
                            />
                        </div>
                    )}
                    {!isFormVisible && (
                        <div className="content-sub">
                            <div className="info">
                                {!isFormVisible && !room.isPublic && (
                                    <div className="participants">
                                        <div>
                                            {translations.Rooms.Participants}:
                                        </div>{" "}
                                        {room.users &&
                                            room.users.length > 0 &&
                                            room.users.map((user) => (
                                                <div
                                                    className="participant"
                                                    key={user.id}
                                                >
                                                    <Link
                                                        to={`/user/${user.id}`}
                                                    >
                                                        {user.nick}
                                                    </Link>
                                                </div>
                                            ))}
                                    </div>
                                )}

                                <div className="created">
                                    {translations.Rooms.Created}:{" "}
                                    {getTimeAgo(room.created)}
                                </div>
                            </div>

                            <div className="menu">
                                <div className="links">
                                    {showActionButtons() && (
                                        <div
                                            className="link-item"
                                            onClick={handleShowForm}
                                        >
                                            <img
                                                src={EditIcon}
                                                alt={translations.Icons.Edit}
                                                title={translations.Icons.Edit}
                                            />
                                        </div>
                                    )}
                                    {showActionButtons() && (
                                        <div className="link-item">
                                            <img
                                                src={DeleteIcon}
                                                alt={translations.Icons.Delete}
                                                title={
                                                    translations.Icons.Delete
                                                }
                                                onClick={handleDelete}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    {isFormVisible && (
                        <RoomEditForm
                            room={room}
                            formSubmitted={handleEditFormSubmitted}
                            formClosed={handleEditFormClosed}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Room;
