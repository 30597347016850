export const VALIDATION = {
    MIN_CONTENT_SIZE: 3,
    MAX_CONTENT_SIZE: 10000,
    MAX_USER_NAME_SIZE: 20,
    MIN_PASSWORD_SIZE: 7,
    MAX_PASSWORD_SIZE: 15,
};

export const DEFAULTS = {
    PAGE: 1,
    PAGE_SIZE: 20,
    PAGE_SIZE_NORMAL: 10,
    PAGE_SIZE_SHORT: 5,
    USERS_PAGE_SIZE: 100,
    ORDER: 1,
    TOP_CHILD_RATING: 9,
    RANDOM_CHILD_RATING: 7,
};

export const LIST_TYPE = {
    ALL: 1,
    RECENT: 2,
    RATING: 3,
};

export const VOTE = {
    SINGLE: 1,
    DOUBLE: 2,
    NEUTRAL: 0,
};

export const BADGES = {
    USER_OF_THE_WEEK: 1,
    CHILD_OF_THE_WEEK: 2,
    BATTLE_WINNER: 3,
    CONTEST_WINNER: 4,
    DOUGLAS_ADAMS_42: 5,
    VIP: 6,
    USER_OF_THE_YEAR: 8,
    CHILD_OF_THE_YEAR: 9,
    USER_OF_THE_MONTH: 10,
};

export const EMAILS = {
    ADMIN: "graysudak@gmail.com",
};

export const PARENT_TYPE = {
    RECENT: 1,
    RANDOM: 2,
};

export const ROOM_TYPE = {
    PUBLIC: 1,
    PRIVATE: 2,
};

export const EVENT_TYPE = {
    CHILD_ADDED: 1,
    CHILD_COMMENT_ADDED: 2,
    CHILD_ADDED_TO_FAVORITES: 3,
    VOTE_ADDED: 101,
    VOTE_DOUBLE_ADDED: 102,
    NEWS_ADDED: 110,
    NEWS_COMMENT_ADDED: 111,
    BADGE_ADDED: 120,
    PARENT_ADDED: 11,
    MESSAGE_ADDED: 201,
    USER_LOGGED_IN: 301,
};

export const ROUTES = {
    CHILD_PAGE_TYPE_WITH_COMMENTS: "comments",
};

export const LANGUAGE = {
    RUSSIAN: "ru",
    ENGLISH: "en",
};

export const SITES = {
    RUKME: {
        protocol: "https",
        host: "rukme.ru",
        port: "8800",
    },
    LBF: {
        protocol: "https",
        host: "letsbefunny.com",
        port: "8801",
    },
};

export const NOVEL_PART_TYPE = {
    BEGINNING: 1,
    CONTINUE: 2,
    ENDING: 3,
};

export const API_KEY =
    "42c269629e2a29da31ebc8d10728b7b2bafc7162277ddedb09b817be427fbd0b";
