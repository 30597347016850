import axios from "axios";
import { constants, translations, SERVICE_URL } from "../base.js";

// Create an axios instance with headers for all requests
const apiClient = axios.create({
    baseURL: SERVICE_URL,
    headers: {
        "x-api-key": constants.API_KEY,
    },
});

export const NewsApi = {
    /**
     * Gets news
     *
     * @param {number} lastLoadedId
     * @param {number} count
     */
    getNews: async (
        count = constants.DEFAULTS.PAGE_SIZE_NORMAL,
        lastLoadedId = 0
    ) => {
        try {
            const endpointUrl = `${SERVICE_URL}/news/list/${count}/${lastLoadedId}`;

            const result = await apiClient.get(endpointUrl);

            if (result.data && result.data.length > 0) {
                return result.data;
            }
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return [];
    },

    /**
     * Gets a news by id
     *
     * @param {number} id - news' id
     * @returns {object} news
     */
    getNewsById: async (id) => {
        try {
            const endpointUrl = `${SERVICE_URL}/news/getNewsById/${id}`;

            const result = await apiClient.get(endpointUrl);

            return result.data;
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "NEWS_NOT_FOUND":
                    error = translations.News.NewsNotFound;
                    break;
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }
    },

    /**
     * Edits the news
     *
     * @param {number} id
     * @param {string} title
     * @param {string} content
     */
    editNews: async (id, title, content) => {
        try {
            const endpointUrl = `${SERVICE_URL}/news/edit`;
            const params = { id: id, title: title, content: content };

            await apiClient.post(endpointUrl, params);
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "CONTENT_TOO_SHORT":
                    error = translations.Errors.ContentTooShort;
                    break;
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return { success: true };
    },

    /**
     * Deletes the news
     *
     * @param {number} id
     */
    deleteNews: async (id) => {
        try {
            const endpointUrl = `${SERVICE_URL}/news/delete`;
            const params = { id: id };

            await apiClient.post(endpointUrl, params);
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return { success: true };
    },

    /**
     * Adds a news
     *
     * @param {string} title
     * @param {string} content
     */
    addNews: async (title, content) => {
        try {
            const endpointUrl = `${SERVICE_URL}/news/add`;
            const params = { title: title, content: content };

            await apiClient.post(endpointUrl, params);
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "NEWS_CONTENT_TOO_SHORT":
                    error = translations.Errors.ContentTooShort;
                    break;
                case "NEWS_TITLE_TOO_SHORT":
                    error = translations.Errors.TitleTooShort;
                    break;
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return { success: true };
    },
};
