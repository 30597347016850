import axios from "axios";
import { constants, translations, SERVICE_URL } from "../base.js";

// Create an axios instance with headers for all requests
const apiClient = axios.create({
    baseURL: SERVICE_URL,
    headers: {
        "x-api-key": constants.API_KEY,
    },
});

export const RoomApi = {
    /**
     * Gets user's rooms
     *
     * @param {number} userId
     * @param {number} count
     * @param {number} lastLoadedId
     * @returns {Array} rooms
     */
    getUserRooms: async (
        userId,
        count = constants.DEFAULTS.PAGE_SIZE_NORMAL,
        lastLoadedId = 0
    ) => {
        try {
            const endpointUrl = `${SERVICE_URL}/rooms/list/${userId}/${count}/${lastLoadedId}`;

            const result = await apiClient.get(endpointUrl);

            if (result.data && result.data.length > 0) {
                return result.data;
            }

            return [];
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return [];
    },

    /**
     * Gets public rooms
     *
     * @param {number} count
     * @param {number} lastLoadedId
     * @returns {Array} rooms
     */
    getPublicRooms: async (
        count = constants.DEFAULTS.PAGE_SIZE_NORMAL,
        lastLoadedId = 0
    ) => {
        try {
            const endpointUrl = `${SERVICE_URL}/rooms/listp/${count}/${lastLoadedId}`;

            const result = await apiClient.get(endpointUrl);

            if (result.data && result.data.length > 0) {
                return result.data;
            }

            return [];
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return [];
    },

    /**
     * Gets a room and all its data by id
     *
     * @param {number} id - room's id
     * @returns {object} room
     */
    getRoomInfo: async (id) => {
        try {
            const endpointUrl = `${SERVICE_URL}/rooms/info/${id}`;

            const result = await apiClient.get(endpointUrl);

            return result.data;
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "ROOM_NOT_FOUND":
                    error = translations.Rooms.RoomNotFound;
                    break;
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }
    },

    /**
     * Edits the room
     *
     * @param {number} id
     * @param {number} ownerId
     * @param {string} name
     * @param {string} description
     * @param {number} isPublic
     * @param {array} users
     */
    editRoom: async (id, ownerId, name, description, isPublic, userIds) => {
        try {
            const endpointUrl = `${SERVICE_URL}/rooms/edit`;
            const params = {
                id: id,
                ownerId: ownerId,
                name: name,
                description: description,
                isPublic: isPublic,
                users: userIds,
            };

            await apiClient.post(endpointUrl, params);
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "CONTENT_TOO_SHORT":
                    error = translations.Errors.ContentTooShort;
                    break;
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return { success: true };
    },

    /**
     * Deletes the room
     *
     * @param {number} id
     */
    deleteRoom: async (id) => {
        try {
            const endpointUrl = `${SERVICE_URL}/rooms/delete`;
            const params = {
                id: id,
            };

            await apiClient.post(endpointUrl, params);
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return { success: true };
    },

    /**
     * Deletes the room
     *
     * @param {number} id
     */
    deleteRoom: async (id) => {
        try {
            const endpointUrl = `${SERVICE_URL}/rooms/delete`;
            const params = { id: id };

            await apiClient.post(endpointUrl, params);
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                case "ERR_NETWORK":
                    error = translations.Errors.ServiceIsTemporaryUnavalable;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return { success: true };
    },

    /**
     * Adds a room
     *
     * @param {string} name
     * @param {string} description
     */
    addRoom: async (name, description, isPublic, ownerId, userIds) => {
        try {
            const endpointUrl = `${SERVICE_URL}/rooms/add`;
            const params = {
                name: name,
                description: description,
                ownerId: ownerId,
                isPublic: isPublic,
                users: userIds,
            };

            await apiClient.post(endpointUrl, params);
        } catch (exception) {
            const errorMessage = exception.response?.data?.error;
            let error = "";

            switch (errorMessage) {
                case "NEWS_CONTENT_TOO_SHORT":
                    error = translations.Errors.ContentTooShort;
                    break;
                case "NEWS_TITLE_TOO_SHORT":
                    error = translations.Errors.TitleTooShort;
                    break;
                case "INVALID_DATA":
                    error = translations.Errors.InvalidData;
                    break;
                default:
                    error = translations.Errors.GeneralError;
                    break;
            }

            return { error: error };
        }

        return { success: true };
    },
};
