import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { constants, translations } from "../../base.js";
import { getTimeAgo } from "../../utils/dates.js";
import { AuthContext } from "../../context/authContext.js";
import { ChildApi } from "../../api/childApi.js";
import ChildEditForm from "./ChildEditForm.jsx";
import Comments from "../comment/Comments.jsx";
import ParentChildren from "../../components/parent/ParentChildren.jsx";
import CommentIcon from "../../images/comments.svg";
import SendIcon from "../../images/send.svg";
import DeleteIcon from "../../images/delete.svg";
import EditIcon from "../../images/edit.svg";
import FaceHappy from "../../images/smiley-face-happy.svg";
import FaceVeryHappy from "../../images/smiley-face-very-happy.svg";
import FaceVeryNeutral from "../../images/smiley-face-neutral.svg";
import NotFavoriteIcon from "../../images/heart-empty.svg";
import FavoriteIcon from "../../images/heart.svg";
import SpinnerRound from "../../components/common/SpinnerRound.jsx";

const Child = ({
    child,
    deleteChild,
    hasParentLink = true,
    showVoting = false,
    showRating = false,
    showComments = false,
}) => {
    const { currentUser } = useContext(AuthContext);
    const [childRating, setChildRating] = useState(child.rating);
    const [isCommentsVisible, setIsCommentsVisible] = useState(showComments);
    const [isVotingVisible, setIsVotingVisible] = useState(showVoting);
    const [isRatingVisible, setIsRatingVisible] = useState(showRating);
    const [isLoading, setIsLoading] = useState(false);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isFavorite, setIsFavorite] = useState(child?.favorite);
    const [isShowChildren, setIsShowChildren] = useState(false);
    const [selectedParentId, setSelectedParentId] = useState(null);

    const [isProcessing, setIsProcessing] = useState(false);

    const location = useLocation();
    const showShareButton = !location.pathname.includes("/child/");

    const { voteForChild, addChildToFavorites, removeChildFromFavorites } =
        ChildApi;

    useEffect(() => {
        const fetchData = async () => {
            if (
                !currentUser ||
                (currentUser && currentUser?.id == child.userId) ||
                child.voted
            ) {
                setIsRatingVisible(true);
            }

            if (
                currentUser &&
                currentUser?.id != child.userId &&
                !child.voted
            ) {
                setIsVotingVisible(true);
            }
        };

        fetchData();
    }, [currentUser, child]);

    const handleShowForm = () => {
        setIsFormVisible((prev) => !prev);
    };

    const handleEditFormSubmitted = (success, newContent = null) => {
        if (success) {
            setIsFormVisible((prev) => !prev);

            if (newContent) {
                child.content = newContent;
            }
        }
    };

    const handleEditFormClosed = () => {
        setIsFormVisible(false);
    };

    const handleChildVoted = async (voteType) => {
        setIsVotingVisible(false);

        setIsLoading(true);

        const result = await voteForChild(child.id, currentUser?.id, voteType);

        setIsLoading(false);

        if (!result?.error) {
            setIsRatingVisible(true);

            if (voteType != constants.VOTE.NEUTRAL) {
                setChildRating((prev) => prev + 1);
            }
        }
    };

    const handleAddToFavorites = async (e) => {
        setIsProcessing(true);
        const result = await addChildToFavorites(child.id, currentUser?.id);

        if (!result?.error) {
            setIsFavorite(true);
        }

        setIsProcessing(false);
    };

    const handleRemoveFromFavorites = async (e) => {
        setIsProcessing(true);
        const result = await removeChildFromFavorites(
            child.id,
            currentUser?.id
        );

        if (!result?.error) {
            setIsFavorite(false);
        }
        setIsProcessing(false);
    };

    const handleDelete = async (e) => {
        deleteChild(child.id);
    };

    const handleShowComments = () => {
        setIsCommentsVisible((prev) => !prev);
    };

    const handleShowChildren = (id) => {
        setSelectedParentId(id);
        setIsShowChildren(true);
        document.body.classList.add("no-scroll");
    };

    const handleHideChildren = () => {
        setSelectedParentId(null);
        setIsShowChildren(false);
        document.body.classList.remove("no-scroll");
    };

    // Show buttons if:
    // - User is the author
    // - User is super admin
    const showActionButtons = () => {
        return (
            currentUser &&
            (currentUser.id === child.userId || currentUser.isSuperAdmin)
        );
    };

    // Show buttons if:
    // - User is super admin
    const showToAdmin = () => {
        return currentUser?.isSuperAdmin;
    };

    return (
        child && (
            <div className="card">
                <div className="flex-container">
                    <div className="card-content">
                        <div
                            className={`parent-content ${
                                hasParentLink && "clickable"
                            }`}
                            onClick={() =>
                                hasParentLink
                                    ? handleShowChildren(child.parentId)
                                    : null
                            }
                        >
                            {child.parentContent
                                ?.split("\n")
                                .map((item, key) => {
                                    return <p key={key}>{item}</p>;
                                })}
                        </div>
                        {!isFormVisible && (
                            <div className="content">
                                {child.content?.split("\n").map((item, key) => {
                                    return <p key={key}>{item}</p>;
                                })}
                            </div>
                        )}
                        {isFormVisible && (
                            <ChildEditForm
                                child={child}
                                formSubmitted={handleEditFormSubmitted}
                                formClosed={handleEditFormClosed}
                            />
                        )}
                        <div className="content-sub child">
                            <div className="info">
                                {getTimeAgo(child.created)}
                                {!isVotingVisible && (
                                    <span>
                                        {" "}
                                        &middot;{" "}
                                        <Link to={`/user/${child.userId}`}>
                                            @{child.nick}
                                        </Link>
                                    </span>
                                )}
                                {child.modified && (
                                    <span>
                                        <br />[
                                        {translations.DateAndTime.Modified}{" "}
                                        {getTimeAgo(child.modified)}]
                                    </span>
                                )}
                            </div>
                            <div className="menu">
                                <div className="links">
                                    {currentUser &&
                                        (isFavorite ? (
                                            <div
                                                className="link-item"
                                                onClick={
                                                    handleRemoveFromFavorites
                                                }
                                            >
                                                <img
                                                    src={FavoriteIcon}
                                                    alt={
                                                        translations.Icons
                                                            .RemoveFromFavorites
                                                    }
                                                    title={
                                                        translations.Icons
                                                            .RemoveFromFavorites
                                                    }
                                                    onClick={
                                                        !isProcessing
                                                            ? handleRemoveFromFavorites
                                                            : null
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                className="link-item"
                                                onClick={
                                                    !isProcessing
                                                        ? handleAddToFavorites
                                                        : null
                                                }
                                            >
                                                <img
                                                    src={NotFavoriteIcon}
                                                    alt={
                                                        translations.Icons
                                                            .AddToFavorites
                                                    }
                                                    title={
                                                        translations.Icons
                                                            .AddToFavorites
                                                    }
                                                />
                                            </div>
                                        ))}
                                    <div
                                        className="link-item comments"
                                        onClick={handleShowComments}
                                    >
                                        <img
                                            src={CommentIcon}
                                            className="comments"
                                            alt={translations.Icons.Comments}
                                            title={translations.Icons.Comments}
                                        />
                                        {child.commentCnt !== 0 ? (
                                            <span className="count">
                                                {child.commentCnt}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    {showToAdmin() && (
                                        <div
                                            className="link-item"
                                            onClick={handleShowForm}
                                        >
                                            <img
                                                src={EditIcon}
                                                alt={translations.Icons.Edit}
                                                title={translations.Icons.Edit}
                                            />
                                        </div>
                                    )}
                                    {showActionButtons() && (
                                        <div
                                            className="link-item"
                                            onClick={handleDelete}
                                        >
                                            <img
                                                src={DeleteIcon}
                                                alt={translations.Icons.Delete}
                                                title={
                                                    translations.Icons.Delete
                                                }
                                            />
                                        </div>
                                    )}

                                    {showShareButton && (
                                        <div className="link-item">
                                            <Link to={`/child/${child.id}`}>
                                                <img
                                                    src={SendIcon}
                                                    alt={
                                                        translations.Icons.Share
                                                    }
                                                    title={
                                                        translations.Icons.Share
                                                    }
                                                />
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="child-sidebar">
                        {isLoading && (
                            <div className="rating">
                                <SpinnerRound />
                            </div>
                        )}
                        {isRatingVisible && (
                            <div className="rating">{childRating}</div>
                        )}
                        {isVotingVisible && (
                            <div className="buttons">
                                <img
                                    src={FaceHappy}
                                    alt={translations.Children.Funny}
                                    title={translations.Children.Funny}
                                    onClick={() =>
                                        handleChildVoted(constants.VOTE.SINGLE)
                                    }
                                />
                                <img
                                    src={FaceVeryHappy}
                                    alt={translations.Children.VeryFunny}
                                    title={translations.Children.VeryFunny}
                                    onClick={() =>
                                        handleChildVoted(constants.VOTE.DOUBLE)
                                    }
                                />
                                <img
                                    src={FaceVeryNeutral}
                                    alt={translations.Children.Neutral}
                                    title={translations.Children.Neutral}
                                    onClick={() =>
                                        handleChildVoted(constants.VOTE.NEUTRAL)
                                    }
                                    className="small"
                                />
                            </div>
                        )}
                    </div>
                </div>
                {isCommentsVisible && (
                    <div className="card-comments">
                        <Comments childId={child.id} userId={child.userId} />
                    </div>
                )}
                {isShowChildren && (
                    <ParentChildren
                        id={selectedParentId}
                        hideChildren={handleHideChildren}
                    />
                )}
            </div>
        )
    );
};

export default Child;
