/**
 * Checks if the local storage settings are set and if so return all as an object
 *
 * @returns {object}
 */
export const getSettings = () => {
    const settings = JSON.parse(localStorage.getItem("settings") || null);

    if (settings) {
        return settings;
    }

    return null;
};

/**
 * Checks if the local storage settings are set and if settings have the property with the key specified
 * If so return the property
 *
 * @returns {object}
 */
export const getSetting = (key) => {
    const settingsParsed = JSON.parse(localStorage.getItem("settings") || null);

    if (settingsParsed && settingsParsed.hasOwnProperty(key)) {
        return settingsParsed[key];
    }

    return null;
};

export const setSetting = (key, value) => {
    const settings = localStorage.getItem("settings");
    let settingsParsed = JSON.parse(settings) || {};
    settingsParsed[key] = value;
    localStorage.setItem("settings", JSON.stringify(settingsParsed));
};
